import { SeverityPill } from "@components/SeverityPill/SeverityPill";
import UsernameAvatar from "@components/UsernameAvatar/UsernameAvatar";
import { IUserContextType, UserContext } from "@context/UserProvider";
import { SnackbarProps } from "@hoc/AlertPopover";
import useDebounce from "@hooks/useDebounce";
import {
  Box,
  FormControlLabel,
  Paper,
  Radio,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ToggleButton,
  Typography,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import { useEditAppointmentTypeMutation } from "@store/services/appointmentTypesService";
import { palette } from "@theme/palette";
import { formatCost, formatDuration } from "@utils/typographyUtils";
import { IAppointmentType } from "appointmentTypes.types";
import { IEmployee } from "employees-types";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

interface IAppointmentsTypesTableProps extends SnackbarProps {
  appointmentType: IAppointmentType;
  employees: IEmployee[];
}

const AppointmentsTypesTable: React.FC<IAppointmentsTypesTableProps> = ({ appointmentType, employees, snackbarShowMessage }) => {
  if (!appointmentType) return null;

  const { userDetails } = useContext(UserContext) as IUserContextType;
  const { t } = useTranslation();
  const [editAppointmentType] = useEditAppointmentTypeMutation();

  const [description, setDescription] = useState<string>(appointmentType.description || "");
  const [isReservationEnabled, setIsReservationEnabled] = useState<boolean>(appointmentType.isReservationEnabled);
  const [assignedEmployeesIds, setAssignedEmployeesIds] = useState<IEmployee[]>(appointmentType.assignedEmployees || []);
  const [selectedPriceId, setSelectedPriceId] = useState<string>(appointmentType.pricing.find((price) => price.isReservationEnabled)?._id || "");

  const debouncedDescription = useDebounce(description, 1000);
  const debouncedIsReservationEnabled = useDebounce(isReservationEnabled, 1000);
  const debouncedAssignedEmployeesIds = useDebounce(assignedEmployeesIds, 1000);
  const debouncedSelectedPriceId = useDebounce(selectedPriceId, 1000);

  useEffect(() => {
    setDescription(appointmentType.description || "");
    setIsReservationEnabled(appointmentType.isReservationEnabled);
    setAssignedEmployeesIds(appointmentType.assignedEmployees || []);
    setSelectedPriceId(appointmentType.pricing.find((price) => price.isReservationEnabled)?._id || "");
  }, [appointmentType]);

  useEffect(() => {
    if (
      debouncedDescription !== appointmentType.description ||
      debouncedIsReservationEnabled !== appointmentType.isReservationEnabled ||
      JSON.stringify(debouncedAssignedEmployeesIds.map((emp) => emp._id)) !==
        JSON.stringify(appointmentType.assignedEmployees.map((emp) => emp._id)) ||
      debouncedSelectedPriceId !== (appointmentType.pricing.find((price) => price.isReservationEnabled)?._id || "")
    ) {
      handleSaveAllChanges();
    }
  }, [debouncedDescription, debouncedIsReservationEnabled, debouncedAssignedEmployeesIds, debouncedSelectedPriceId]);

  const handleDescriptionChange = (newDescription: string) => {
    setDescription(newDescription);
  };

  const handleIsReservationEnabledChange = async () => {
    const newIsReservationEnabled = !isReservationEnabled;
    setIsReservationEnabled(newIsReservationEnabled);

    try {
      await editAppointmentType({
        typeId: appointmentType._id,
        updatedAppointmentType: {
          isReservationEnabled: newIsReservationEnabled,
        },
      }).unwrap();
      snackbarShowMessage(
        newIsReservationEnabled ? t("reservations.appointmentType.reservationEnabled") : t("reservations.appointmentType.reservationDisabled"),
        "success"
      );
    } catch (error) {
      snackbarShowMessage(t("reservations.appointmentType.updateReservationError"), "error");
    }
  };

  const handleAssignedEmployeeChange = (employee: IEmployee, checked: boolean) => {
    setAssignedEmployeesIds((prev) => (checked ? [...prev, employee] : prev.filter((emp) => emp._id !== employee._id)));
  };

  const handlePricingChange = (priceId: string) => {
    setSelectedPriceId(priceId);
  };

  const handleSaveAllChanges = async () => {
    try {
      await editAppointmentType({
        typeId: appointmentType._id,
        updatedAppointmentType: {
          description,
          assignedEmployees: assignedEmployeesIds,
          pricing: appointmentType.pricing.map((p) => ({
            ...p,
            isReservationEnabled: p._id === selectedPriceId,
          })),
        },
      }).unwrap();
      snackbarShowMessage(t("reservations.appointmentType.snackbar_messages.success"), "success");
    } catch (error) {
      snackbarShowMessage(t("reservations.appointmentType.snackbar_messages.error"), "error");
    }
  };

  const updatedEmployees = useMemo(() => {
    return [
      {
        ...userDetails,
        isCurrentUser: true,
      },
      ...employees,
    ] as IEmployee[];
  }, [userDetails, employees]);

  return (
    <Box position="relative">
      <Paper sx={{ padding: 2 }}>
        <Box sx={{ marginBottom: "2rem" }}>
          <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between" sx={{ mb: 2 }}>
            <Typography variant="h6" gutterBottom>
              {appointmentType.name}
            </Typography>
            <ToggleButton value="check" selected={isReservationEnabled} onChange={handleIsReservationEnabledChange} size="small">
              {isReservationEnabled ? t("disable") : t("enable")} {t("reservations.appointmentType.onlineReservations")}
            </ToggleButton>
          </Stack>

          <TextField
            fullWidth
            multiline
            minRows={6}
            variant="outlined"
            size="small"
            label={t("reservations.appointmentType.description")}
            value={description}
            onChange={(e) => handleDescriptionChange(e.target.value)}
            disabled={!isReservationEnabled}
          />

          <Typography variant="subtitle1" gutterBottom sx={{ mt: 6 }}>
            {t("reservations.appointmentType.pricing")}
          </Typography>

          {appointmentType.pricing.length === 0 ? (
            <Typography color="textSecondary" sx={{ mb: 2 }}>
              {t("reservations.appointmentType.addPriceToEnableOnlineReservation")}
            </Typography>
          ) : (
            <TableContainer>
              <Table size="small" aria-label="tabela cen">
                <TableHead>
                  <TableRow>
                    <TableCell>{t("reservations.appointmentType.duration")}</TableCell>
                    <TableCell>{t("reservations.appointmentType.cost")}</TableCell>
                    <TableCell align="right">{t("reservations.appointmentType.includeInOnlineReservation")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {appointmentType.pricing.map((price, index) => {
                    const isLastRow = index === appointmentType.pricing.length - 1;
                    return (
                      <TableRow key={price._id}>
                        <TableCell sx={isLastRow ? { borderBottom: "none" } : {}}>
                          <Typography variant="subtitle2">{formatDuration(price.duration)}</Typography>
                        </TableCell>
                        <TableCell sx={isLastRow ? { borderBottom: "none" } : {}}>
                          <SeverityPill color="info">{formatCost(price.cost)}</SeverityPill>
                        </TableCell>
                        <TableCell sx={isLastRow ? { borderBottom: "none" } : {}} align="right">
                          <FormControlLabel
                            control={
                              <Radio
                                value={price._id}
                                checked={selectedPriceId === price._id}
                                onChange={() => handlePricingChange(price._id)}
                                disabled={!isReservationEnabled}
                              />
                            }
                            label=""
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {!!updatedEmployees.length && (
            <Box>
              <Typography variant="subtitle1" gutterBottom sx={{ mt: 6 }}>
                {t("reservations.appointmentType.assignedEmployees")}
              </Typography>

              <TableContainer>
                <Table size="small" aria-label="tabela pracowników">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("reservations.appointmentType.employeeFullName")}</TableCell>
                      <TableCell align="right">{t("reservations.appointmentType.includeInOnlineReservation")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {updatedEmployees.map((employee: IEmployee, index) => {
                      const isLastRow = index === updatedEmployees.length - 1;
                      const isAssigned = assignedEmployeesIds.some((emp) => emp._id === employee._id);
                      return (
                        <TableRow key={employee._id}>
                          <TableCell sx={isLastRow ? { borderBottom: "none" } : {}}>
                            <UsernameAvatar
                              avatar
                              firstname={employee.firstname}
                              lastname={employee.lastname}
                              email={employee.email}
                              additionalInfo={
                                // @ts-expect-error - isCurrentUser is not in IEmployee
                                employee.isCurrentUser && <SeverityPill color="primary">{t("reservations.scheduler.my_account")}</SeverityPill>
                              }
                            />
                          </TableCell>
                          <TableCell sx={isLastRow ? { borderBottom: "none" } : {}} align="right">
                            <FormControlLabel
                              control={
                                <Radio
                                  checked={isAssigned}
                                  onClick={() => handleAssignedEmployeeChange(employee, !isAssigned)}
                                  disabled={!isReservationEnabled}
                                />
                              }
                              label=""
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </Box>
      </Paper>

      {!isReservationEnabled && (
        <Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          display="flex"
          alignItems="center"
          justifyContent="center"
          bgcolor={alpha(palette.grey[100], 0.8)}
          zIndex={1}
        >
          <Box textAlign="center" sx={{ mx: 2 }}>
            <Typography variant="h6" mt={2} color="text.disabled">
              {t("reservations.appointmentType.info_text")}
            </Typography>
            <ToggleButton value="check" onChange={handleIsReservationEnabledChange} size="small">
              {t("enable")} {t("reservations.appointmentType.onlineReservations")}
            </ToggleButton>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default AppointmentsTypesTable;
